@mixin mobile() {
    @media screen and (max-width: $break-s), screen and (max-height: 600px) {
        @content;
    }
}

@mixin tablet() {
    @media screen and (max-width: $break-m) {
        @content;
    }
}

@mixin small-desktop() {
    @media screen and (max-width: $break-l) {
        @content;
    }
}

@mixin large-desktop() {
    @media screen and (max-width: $break-xl) {
        @content;
    }
}

@mixin pseudo($display: block, $position: absolute, $content: '') {
    display: $display;
    position: $position;
    content: $content;
}

@mixin heading {
    text-transform: uppercase;
    font-size: 6rem;
    font-weight: 300;
    line-height: 1;
    margin: 1rem 0;
    letter-spacing: .05ch;

    @include mobile() {
        font-size: 4rem;
    }
}

@mixin landingButton {
    position: absolute;
    text-transform: uppercase;
    align-items: center;
    display: flex;
    border: none;
    font-family: inherit;
    color: #fff;
    font-size: initial;
    background: none;
    outline: none;
}