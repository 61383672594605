.opp-showreel {
    display: block;
    background: #000;
    height: 100%;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    video {
        width: 80%;
        height: auto;
    }
}

.opp-showreel--button {
    @include landingButton();
}

.opp-showreel--button {
    bottom: 0;
    z-index: 3;
    left: 50%;
    flex-direction: column;
    transform: translateX(-50%);
    img {
        margin-top: 1rem;
    }

    svg {
        margin-top: .5rem;
        stroke-dasharray: 42, 6;
        stroke-dashoffset: 0;
        animation: scroll 2s linear infinite;
    }
}

@keyframes scroll {
    from {
        stroke-dashoffset: 48;
    }
    to {
        stroke-dashoffset: 0;
    }
}