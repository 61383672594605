.opp-shutter {
    overflow: hidden;
    display: block;
    position: fixed;
    top: $bar-height;
    left: 0;
    right: 0;
    bottom: $bar-height;
    z-index: 10;
    pointer-events: none;

    div {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        
        &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: 11;
            display: block;
            left: 0;
            right: 0;
            height: 50%;
            background: #000;
        }
    }
}

.opp-shutter div {
    &:before {
        top: 0;
        animation: slideUpOut 300ms ease-out forwards;
    }

    &:after {
        bottom: 0;
        animation: slideDownOut 300ms ease-out forwards;
    }
    @for $i from 1 through 1 {
        &:nth-child(#{$i}) {
            left: ($i - 1)*50%;
            &:before,
            &:after {
                animation-delay: ($i - 1)*50ms;
            }
        }
    }
}

@keyframes slideUpOut {
    to {
        transform: translateY(-100%);
    }
}
@keyframes slideDownOut {
    to {
        transform: translateY(100%);
    }
}

@keyframes slideUpIn{
    from {
        transform: translateY(100%);
    }
    to {
        transform: none;
    }
}
@keyframes slideDownIn {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: none;
    }
}