@mixin opp-main() {
    display: block;
    height: 100%;
    margin: 0;
    position: relative;
    overflow: hidden;
}

@mixin opp-content() {
    display: block;
    position: absolute;
    // overflow-x: scroll;
    font-size: 18px;
    font-weight: 300;
    background: rgba(#000, .35);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6rem;

    @include mobile {
        padding: 2rem;
        display: block;
        overflow: auto;
        font-size: 16px;
    }

    &--text {
        width: 500px;
        max-width: 100%;
        perspective: 600px;
        margin-left: 2rem;
		@media screen and (min-width: $break-m) {
			&.swipe-enter {
				transform: translateY(-33%);
				opacity: 0;
				position: absolute;
				top: 50%;
			}
			
			&.swipe-enter-active,
			&.swipe-enter-done {
				transform: translateY(-50%);
				opacity: 1;
				transition: 300ms ease-in-out;
				position: absolute;
				top: 50%;
			}

			&.swipe-enter-done  {
				// opacity: 1;
				// transition: 300ms ease;
				// position: absolute;
			}
			
			&.swipe-exit.swipe-exit-active  {
				transform: translateY(-17%);
				opacity: 0;
				transition: 300ms ease-in-out;
				// position: absolute;
			}
		}

        @include tablet {
			margin-left: 0;

			&.swipe-exit-done,
			&.swipe-exit-active {
				display: none;
			}
        }
    }

    &--heading {
        @include heading;
        transform-style: preserve-3d;
        transform-origin: 50% 0;
        animation: swingDown 600ms ease-out forwards;
    }

    &--blurb {
        animation: fadeUp 600ms ease-out forwards;
    }
}

@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeDown {
    from {
        opacity: 0;
        transform: translateY(-100%);
    }

    to {
        opacity: 1;
    }
}

@keyframes swingDown {
    from {
        opacity: 0;
        transform: translateY(-10%) rotateX(-30deg);
    }

    to {
        opacity: 1;
    }
}

@mixin opp-button {
    display: inline-block;
    padding: 0;
    font-size: 1.618rem;
    background: transparent;
    border: none;
    font-family: $font-heading;
    font-weight: 500;
    letter-spacing: .1ch;
    color: #fff;
    line-height: inherit;
    position: relative;
    z-index: 1;
    transition: 300ms ease;

    &:hover {
        transform: scale(1.05);
    }
}

@mixin opp-video {
    display: block;
    min-width: 100%;
    max-width: calc(1.778 * (100vh - #{$bar-height}*2));
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    &--wrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }
}

@mixin opp-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    text-align: left;
    font-size: 4rem;
    font-family: $font-heading;
    font-weight: 300;
}

@mixin opp-list--item {
    text-transform: uppercase;
    margin: .25em 0;
    line-height: 1;

    &--link {
        color: rgba(#fff, 0.7);
        display: inline-block;
        position: relative;
        z-index: 1;
        transition: 300ms ease;

        &:hover {
            // transform: scale(1.05);
            color: #fff;
            text-shadow: 0 0 20px rgba(#fff, .5);
        }
    }

    &--link__active {
        color: #fff;
    }
}