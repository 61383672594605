.opp-client--list {
	display: flex;
	flex-direction: row;
	transition: transform 300ms ease;
	padding-right: 2rem;
	// height: 300px;

	@include mobile {
		display: block;
		padding-right: 0;
	}
}

.opp-client {
	margin-left: 2rem;
	&--inner {
		background-size: cover;
		background-position: center;
		width: 280px;
		height: 280px;
		margin: 0 auto;
		border-radius: 10000px;
	}

	@include mobile {
		margin: 0 auto;
		margin-bottom: 2rem;
		&--inner {
			width: 160px;
			height: 160px;
		}
	}
}

